import React, { useState, useEffect } from "react";
import {
  Tabs,
  Table,
  Button,
  message,
  Modal,
  Form,
  Input,
  InputNumber,
  Tag,
  Switch,
  Avatar,
  Select,
  Descriptions,
  Typography,
  Divider,
  Tooltip,
  Segmented,
  List,
  Space,
} from "antd";
import { Scanner } from "@yudiel/react-qr-scanner";
import {
  approveBranchRequest,
  deleteUser,
  getUserById,
  queryUsers,
  readBranchRequestedUsers,
  readSignupRequestedUsers,
  readUsers,
  scanAllUsers,
  searchUsers,
  updateUser,
} from "../utils/user";
import styled from "styled-components";
import NewsAdmin from "../components/admin/AdminNews";
import {
  UserOutlined,
  PlusOutlined,
  EnvironmentOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { branchOptions, branchSuffix } from "../constants/branch";
import { EditUserModal } from "../components/modal/EditUserModal";
import DataAdmin from "../components/admin/AdminData";
import { getRank } from "../utils/rank";
import LocationAdmin from "../components/admin/AdminLocation";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { userInfoState } from "../atom/userInfo";
import { useNavigate } from "react-router-dom";
import BannerImageAdmin from "../components/admin/AdminBannerImage";
import { useMediaQuery } from "react-responsive";
import RallyAdmin from "../components/admin/AdminRally";
import { createBranchLog, createUserDeleteLog, getBranchLogs, getFieldFilteredLogs, getUserLogs } from "../utils/log";
import { format, parse } from "date-fns";
import { ko } from "date-fns/locale";
import QrEditModal from "../components/modal/QrEditModal";
import AdminLogs from "../components/admin/AdminLogs";
import LogList from "../components/LogList";

const { TabPane } = Tabs;
const { Option } = Select;

const { Title, Text } = Typography;

type User = any;

const Wrapper = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  .ant-table-cell {
    min-width: 100px;
  }
`;

export default function Admin() {
  const [users, setUsers] = useState<User[]>([]);

  const [loading, setLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(null);

  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});

  const [userLogs, setUserLogs] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [activeUserTable, setActiveUserTable] = useState("allUsers");
  const [branchRequestedUsers, setBranchRequestedUsers] = useState<any>([]);
  const [signupRequestedUsers, setSignupRequestedUsers] = useState<any>([]);

  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [isQRScanModalVisible, setIsQRScanModalVisible] = useState(false);
  const [logUser, setLogUser] = useState("");

  const [totalLength, setTotalLength] = useState(0);
  const [dataSource, setDataSource] = useState<any>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [lastEvaluatedKeys, setLastEvaluatedKeys] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);



  const [branchLogs, setBranchLogs] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const currentDataSource = searchResults.length > 0 ? searchResults : users;
    setDataSource(currentDataSource);
  }, [searchResults, users]);

  useEffect(() => {
    if (!users) return;
    setTotalLength(users.length);
  }, [users]);

  useEffect(() => {
    setHasMore(true);
    setTotal(0);
    setCurrentPage(1);
    if (activeUserTable === "allUsers") {
      fetchUsers(1, pageSize, true); // 처음 로드할 때는 데이터를 리셋합니다.
    } else if (activeUserTable === "branchRequests") {
      fetchBranchRequestedUsers();
    } else if (activeUserTable === "signupRequests") {
      fetchSignupRequestedUsers();
    }
  }, [activeUserTable]);

  useEffect(() => {
    fetchUsers(1, pageSize, true);
  }, []);
  
  const fetchBranchLogs = async () => {
    const res = await getFieldFilteredLogs("branch", 50);
    
    setBranchLogs(res.items);
  };
  useEffect(() => {
    fetchBranchLogs();
  }, []);

  const fetchBranchRequestedUsers = async () => {
    setLoading(true);
    try {
      const result = await readBranchRequestedUsers();
      if (result.success) {
        setBranchRequestedUsers(result.users);
      } else {
        message.error("지점 변경 요청 사용자 정보를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error fetching branch requested users:", error);
      message.error("지점 변경 요청 사용자 정보를 불러오는데 실패했습니다.");
    }
    setLoading(false);
  };

  const fetchSignupRequestedUsers = async () => {
    setLoading(true);
    try {
      const result = await readSignupRequestedUsers();
      if (result.success) {
        setSignupRequestedUsers(result.users);
      } else {
        message.error("가입 승인 대기 사용자 정보를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error fetching signup requested users:", error);
      message.error("가입 승인 대기 사용자 정보를 불러오는데 실패했습니다.");
    }
    setLoading(false);
  };


  const fetchUsers = async (page: number, size: number, reset: boolean = false) => {
    setLoading(true);
    try {
      const result: any = await scanAllUsers(
        size,
        // reset ? null : lastEvaluatedKeys[page - 2]  // 이전 페이지의 lastEvaluatedKey 사용
      );
      if (result.success) {
        if (reset) {
          setUsers(result.users);
          setLastEvaluatedKeys([result.lastEvaluatedKey]);
        } else {
          setUsers(prev => [...prev, ...result.users]);
          setLastEvaluatedKeys(prev => [...prev, result.lastEvaluatedKey]);
        }
        setTotal(result.users.length);

        
      } else {
        message.error("사용자 정보를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("사용자 정보를 불러오는데 실패했습니다.");
    }
    setLoading(false);
  };

  // const handleEdit = (user: User) => {
  //   setEditingUser(user);
  //   setFormData({ ...user });
  //   setIsEditModalVisible(true);
  // };

  const handleEditSubmit = async () => {
    if (!editingUser) return;

    console.log(formData);
    if (formData.coin % 10 !== 0) {
      message.warning("통합 포인트는 10DP 단위로만 변경 가능합니다.");
      return;
    }
    try {
      const updatedUser: any = {
        ...editingUser,
        ...formData,
        score: editingUser.branch !== formData.branch ? 0 : formData.score,
        prevBranch:
          editingUser.branch !== formData.branch
            ? {
                branch: editingUser.branch,
                score: editingUser.score,
              }
            : editingUser.prevBranch || {},
      };

      console.log(updatedUser);
      const result = await updateUser(updatedUser, userInfo, true);
      if (result.success) {
        message.success("사용자 정보가 업데이트되었습니다.");
        setUsers(
          users.map((user) => (user.id === editingUser.id ? updatedUser : user))
        );
        setIsEditModalVisible(false);
      } else {
        message.error("사용자 정보 업데이트에 실패했습니다.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      message.error("사용자 정보 업데이트 중 오류가 발생했습니다.");
    }
  };

  const handleApproveBranchRequest = async (id: any, user: any) => {
    try {
      await approveBranchRequest(id, user.branchRequest);

      const updatedUser: any = {
        ...user,
        // score: 0,
        branch: user.branchRequest,
        prevBranch: {
          branch: user.branch,
          score: user.score,
        },
      };
      await updateUser(updatedUser, true);
      setBranchRequestedUsers(
        branchRequestedUsers.filter((user: any) => user.id !== id)
      );
      setUsers(
        users.map((user) => {
          if (user.id === id) {
            return updatedUser;
          } else {
            return user;
          }
        })
      );

      await createBranchLog(user, userInfo.username);

      message.success(`승인이 완료되었습니다.`);
      await fetchBranchLogs();
    } catch (error) {
      message.error("지점 승인 중 오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    const fetchUserLogs = async () => {
      if (!logUser) return;
      try {
        const logs = await getUserLogs(logUser);
        if (!logs) return;
        setUserLogs(logs);
      } catch (error) {
        message.error("로그 조회에 실패했습니다.");
      }
    };

    fetchUserLogs();
  }, [logUser]);


  const branchRequestColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "사용자명",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "현재 지점",
      dataIndex: "branch",
      key: "branch",
    },
    {
      title: "요청 지점",
      dataIndex: "branchRequest",
      key: "branchRequest",
    },
    {
      title: "작업",
      dataIndex: "id",
      key: "id",
      render: (id: string, v: any) => {
        return (
          <Button onClick={() => handleApproveBranchRequest(id, v)}>
            승인하기
          </Button>
        );
      },
    },
  ];

  const signupRequestColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "사용자명",
      dataIndex: "username",
      key: "username",
    },
  ];

  const openLogPopup = (userId: any, username: string, nickname: string) => {
    const width = 600;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `/user-log?id=${userId}&username=${username}&nickname=${nickname}`,
      "userLogPopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const openEditPopup = (userId: string) => {
    const width = 600;
    const height = 800;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `/user-edit?id=${userId}`,
      "userEditPopup",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === "USER_UPDATED") {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === event.data.user.id ? event.data.user : user
          )
        );
        message.success("사용자 정보가 업데이트되었습니다.");
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);


  const handleDeleteUser = async (userId: string, username: string, nickname: string, branch: string) => {
    Modal.confirm({
      title: '사용자를 삭제하시겠습니까?',
      content: '이 작업은 되돌릴 수 없습니다.',
      okText: '삭제',
      okType: 'danger',
      cancelText: '취소',
      onOk: async () => {
        try {
          const result = await deleteUser(userId);
          if (result.success) {
            message.success('사용자가 성공적으로 삭제되었습니다.');
            setUsers(users.filter(user => user.id !== userId));
            setSearchResults(searchResults.filter((user: any) => user.id !== userId))
            await createUserDeleteLog(userId, branch, username, nickname, userInfo.username)
          } else {
            message.error('사용자 삭제에 실패했습니다.');
          }
        } catch (error) {
          console.error("Error deleting user:", error);
          message.error('사용자 삭제 중 오류가 발생했습니다.');
        }
      },
    });
  };
  
  const columns: any = [
    {
      title: "사용자명",
      dataIndex: "username",
      key: "username",
      fixed: "left",

    },

    {
      title: "닉네임",
      dataIndex: "nickname",
      key: "nickname",
    },

    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "권한",
      dataIndex: "isAdmin",
      key: "isAdmin",
      render: (isAdmin: boolean, data: any) => {
        if(data.isPointAdmin) {
          return <Tag>포인트관리자</Tag>   
        }
        if (isAdmin) {
          
           if (data.isRootAdmin) {
            return <Tag color="gold">대표관리자</Tag>;
          } else {
            return <Tag color="green">지점관리자</Tag>;
          }
        } else {
          return <Tag color="blue">일반사용자</Tag>;
        }
      },
      filters: [
        { text: "대표관리자", value: "representative" },
        { text: "지점관리자", value: "branch" },
        { text: "일반사용자", value: "regular" },
        { text: "포인트관리자", value: "point" },
      ],
      onFilter: (value: string, record: User) => {
        if(value === "point") return record.isPointAdmin
        if (value === "representative")
          return record.isAdmin && record.isRootAdmin;
        if (value === "branch") return record.isAdmin && !record.isRootAdmin;
        if (value === "regular") return !record.isAdmin;
        return false;
      },
    },
    {
      title: "지점",
      dataIndex: "branch",
      key: "branch",
      render: (value: string) => {
        return (
          <div style={{ color: "#aaa" }}>
            <EnvironmentOutlined /> {value}
          </div>
        );
      },
      filters: branchOptions.map((option) => ({
        text: option.label,
        value: option.label,
      })),
      onFilter: (value: string, record: User) => record.branch === value,
    },
    {
      title: "랭킹점수",
      dataIndex: "score",
      key: "score",
      render: (v: any, record: any) => {
        return v?.[record.branch] ?? 0;
      },
      sorter: (a: User, b: User) => (a.score?.[a.branch] || 0) - (b.score?.[b.branch] || 0),
    },
    {
      title: "랭킹등급",
      dataIndex: "score",
      key: "rank",
      render: (score: any, record: any) => getRank(score?.[record.branch] ?? 0),
      sorter: (a: User, b: User) =>
        getRank(a.score).localeCompare(getRank(b.score)),
    },
    {
      title: "지점포인트",
      dataIndex: "point",
      key: "point",
      render: (point: any, record: User) => point?.[record.branch] || 0,
      sorter: (a: User, b: User) => (a.point?.[a.branch] || 0) - (b.point?.[b.branch] || 0),
    },
    {
      title: "통합포인트",
      dataIndex: "coin",
      key: "coin",
      sorter: (a: User, b: User) => a.coin - b.coin,
      render: (v: string) => {
        return `${v} DP`;
      },
    },
    {
      title: "자료실",
      dataIndex: "dataAccessibility",
      key: "dataAccessibility",
      render: (access: boolean) => (
        <Tag color={access ? "green" : "red"}>
          {access ? "접근 가능" : "접근 불가"}
        </Tag>
      ),
      filters: [
        { text: "접근 가능", value: true },
        { text: "접근 불가", value: false },
      ],
      onFilter: (value: boolean, record: User) =>
        record.dataAccessibility === value,
    },
    {
      title: "가입 승인",
      dataIndex: "isApproved",
      key: "isApproved",
      render: (approved: boolean) => (
        <Tag color={approved ? "green" : "red"}>
          {approved ? "승인됨" : "승인안됨"}
        </Tag>
      ),
      filters: [
        { text: "승인됨", value: true },
        { text: "승인안됨", value: false },
      ],
      onFilter: (value: boolean, record: User) => record.isApproved === value,
    },
    {
      title: "작업",
      key: "action",
      render: (_: any, record: User) => (
        <Space.Compact>
          <Button onClick={() => openEditPopup(record.id)}>편집</Button>
          <Button
            onClick={() =>
              openLogPopup(record.id, record.username, record.nickname)
            }
          >
            로그
          </Button>
          <Button
            onClick={() => handleDeleteUser(record.id, record.username, record.nickname, record.branch)}
            danger
            disabled={(!(userInfo.branch === record.branch) || !userInfo.isAdmin) && !userInfo.isRootAdmin}
          >
            삭제
          </Button>
        </Space.Compact>
      ),
    },

  ];

  useEffect(() => {
    if (!(userInfo.isAdmin || userInfo.isPointAdmin)) {
      message.error("접근권한이 없습니다.");
      localStorage.removeItem("userInfo__doubleup");
      setUserInfo({});
      navigate("/");
    }
  }, []);

  const handleSearch = async () => {
    console.log("hi")
    if (searchTerm.trim() === "") {
      setSearchResults([]);

      return;
    }

    setLoading(true);
    const result = await searchUsers(searchTerm);
    if (result.success) {
      setSearchResults(result.users || []);
      setTotalLength(result.users ? result.users.length : 0)
    } else {
      message.error("사용자 검색 중 오류가 발생했습니다.");
    }
    setLoading(false);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // const newPage = pagination.current;
    // const newPageSize = pagination.pageSize;
    
    // if (newPageSize !== pageSize) {
    //   // 페이지 크기가 변경된 경우, 첫 페이지부터 다시 로드
    //   setCurrentPage(1);
    //   setPageSize(newPageSize);
    //   fetchUsers(1, newPageSize, true);
    // } else if (newPage !== currentPage) {
    //   // 페이지가 변경된 경우
    //   setCurrentPage(newPage);
    //   if (newPage > lastEvaluatedKeys.length && hasMore) {
    //     // 새로운 페이지로 이동하는 경우에만 추가 데이터 로드
    //     fetchUsers(newPage, newPageSize);
    //   }
    // }
  
    // 필터링 및 정렬 로직
    const filteredData = users.filter((user) => {
      for (let key in filters) {
        if (filters[key] && filters[key].length > 0) {
          switch(key) {
            case "isAdmin":
              if (filters[key].includes("representative") && !(user.isAdmin && user.isRootAdmin)) return false;
              if (filters[key].includes("branch") && !(user.isAdmin && !user.isRootAdmin)) return false;
              if (filters[key].includes("regular") && user.isAdmin) return false;
              if (filters[key].includes("point") && !user.isPointAdmin) return false;
              break;
            case "branch":
            case "dataAccessibility":
            case "isApproved":
              if (!filters[key].includes(user[key])) return false;
              break;
            default:
              if (!filters[key].includes(String(user[key]))) return false;
          }
        }
      }
      return true;
    });
  
    setTotalLength(filteredData.length);
  
    // 정렬 로직
    if (sorter.field) {
      filteredData.sort((a, b) => {
        if (a[sorter.field] < b[sorter.field])
          return sorter.order === "descend" ? 1 : -1;
        if (a[sorter.field] > b[sorter.field])
          return sorter.order === "descend" ? -1 : 1;
        return 0;
      });
    }
  
    // setDataSource(filteredData.slice((newPage - 1) * newPageSize, newPage * newPageSize));
  };

  const renderUserTable = () => {
    switch (activeUserTable) {
      case "allUsers":
        return (
          <>
            <Input.Search
              placeholder="사용자 이름 또는 전화번호로 검색"
              onSearch={handleSearch}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Table
              size={isMobile ? "small" : "middle"}
              dataSource={dataSource}
              columns={columns}
              rowKey="id"
              loading={loading}
              pagination={{
                // current: currentPage,
                pageSize: 10,
                total: totalLength,
                // showSizeChanger: true,
                // showQuickJumper: true,
                // showTotal: (total) => `Total ${total - 1} items`,
              }}
              onChange={handleTableChange}
              scroll={{ x: true }}
            />
          </>
        );
      case "branchRequests":
        return (
          <>
            <Table
              size={isMobile ? "small" : "middle"}
              dataSource={branchRequestedUsers}
              columns={branchRequestColumns}
              rowKey="id"
              loading={loading}
              pagination={false}
              scroll={{ x: true }}
            />
            <Divider />
            <Title level={4}>지점 변경 목록</Title>
            <LogList logs={branchLogs}/>
          </>
        );
      case "signupRequests":
        return (
          <Table
            size={isMobile ? "small" : "middle"}
            dataSource={signupRequestedUsers}
            columns={signupRequestColumns}
            rowKey="id"
            loading={loading}
            pagination={false}
            scroll={{ x: true }}
          />
        );
    }
  };

  const renderUserInfo = () => {
    return (
      <Descriptions
        bordered
        size={isMobile ? "small" : "middle"}
        style={{ marginBottom: 30 }}
        title="현재 로그인한 계정"
      >
        <Descriptions.Item label="이름">{userInfo.username}</Descriptions.Item>
        <Descriptions.Item label="ID">{userInfo.id}</Descriptions.Item>
        <Descriptions.Item label="지점">{userInfo.branch}</Descriptions.Item>
        <Descriptions.Item label="관리자 유형">
          {userInfo.isAdmin ? (
            userInfo.isRootAdmin ? (
              "대표관리자"
            ) : (
              "지점관리자"
            )
          ) : (
            "포인트관리자"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="랭킹점수">
          {" "}
          {userInfo.score?.[userInfo.branch] ?? 0}
        </Descriptions.Item>
        <Descriptions.Item label="랭킹등급">
          {getRank(userInfo.score?.[userInfo.branch] ?? 0)}
        </Descriptions.Item>
        <Descriptions.Item label="지점포인트">
          {userInfo.point?.[userInfo.branch]
            ? `${userInfo.point[userInfo.branch]} ${
                branchSuffix[userInfo.branch]
              }`
            : `0 ${branchSuffix[userInfo.branch]}`}
        </Descriptions.Item>
        <Descriptions.Item label="통합포인트">
          {userInfo.coin} DXG
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <Wrapper>
      <Title
        level={2}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        관리자 페이지
        {(userInfo.isAdmin || userInfo.isPointAdmin) && (
          <Button
            onClick={() => {
              setIsQRScanModalVisible(true);
            }}
            icon={<QrcodeOutlined />}
          >
            QR 코드 인식
          </Button>
        )}
      </Title>
      <Divider />
      {renderUserInfo()}
      <Divider />
      <Tabs defaultActiveKey="1">
        <TabPane tab="사용자 조회" key="1">
          <Segmented
            options={[
              { label: "전체 사용자", value: "allUsers" },
              { label: "지점 변경 요청", value: "branchRequests" },
              {
                label: <Text>(사용자수 : {totalLength})</Text>,
                value: "",
                disabled: true,
              },
            ]}
            value={activeUserTable}
            onChange={setActiveUserTable}
            style={{ marginBottom: 16 }}
          />
          {renderUserTable()}
        </TabPane>
        {/* <TabPane tab="QR 코드 인식" key="2">
          {!isEditModalVisible && <Scanner onScan={handleQrScan} />}
          {qrResult && <div>{qrResult}</div>}
        </TabPane> */}

        <TabPane tab="대회 예약" key="7" disabled={!userInfo.isAdmin}>
          <RallyAdmin />
        </TabPane>

        <TabPane tab="대회소식" key="3" disabled={!userInfo.isRootAdmin}>
          <NewsAdmin />
        </TabPane>

        <TabPane tab="자료실" key="4" disabled={!userInfo.isRootAdmin}>
          <DataAdmin />
        </TabPane>

        <TabPane tab="지점 정보" key="5" disabled={!userInfo.isRootAdmin}>
          <LocationAdmin />
        </TabPane>

        <TabPane tab="일정표 사진" key="6" disabled={!userInfo.isRootAdmin}>
          <BannerImageAdmin />
        </TabPane>
        <TabPane tab="로그" key="8" disabled={!userInfo.isAdmin}>
          <AdminLogs />
        </TabPane>
      </Tabs>



      <QrEditModal
        isOpen={isQRScanModalVisible}
        setIsOpen={setIsQRScanModalVisible}
        closeModal={() => setIsQRScanModalVisible(false)}
      />
    </Wrapper>
  );
}
