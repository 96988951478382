  import React, { useState, useEffect } from "react";
  import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    DatePicker,
    TimePicker,
    message,
    Space,
    Popconfirm,
    Checkbox,
  } from "antd";
  import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    DatabaseOutlined,
    SearchOutlined
  } from "@ant-design/icons";
  import moment from "moment";
  import {
    createRally,
    updateRally,
    deleteRally,
    getAllRallies,
  } from "../../utils/rally";
  import { useMediaQuery } from "react-responsive";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../atom/userInfo";
import { getUserById, updateUser } from "../../utils/user";
import { createReservationDeleteLog } from "../../utils/log";

  interface Rally {
    id: string;
    name: string;
    date: string;
    time: string;
    prizeMoney: number;
    maximumPeople: number;
    reservationStart: string;
    reservationStartTime: string;
    reservationEnd: string;
    reservationEndTime: string;
    reservations?: any;
    isNoLimit: boolean;
    coinFee: string;
  }

  const RallyAdmin: React.FC = () => {
    const [rallies, setRallies] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingRally, setEditingRally] = useState<Rally | null>(null);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);
    const userInfo = useRecoilValue(userInfoState);

    const [searchText, setSearchText] = useState("");
  const [filteredReservations, setFilteredReservations] = useState<any[]>([])
  
    useEffect(() => {
      fetchRallies();
    }, []);

    const fetchRallies = async () => {
      setLoading(true);
      try {
        const fetchedRallies = await getAllRallies();
        setRallies(fetchedRallies);
      } catch (error) {
        console.error("Error fetching rallies:", error);
        message.error("대회 목록을 불러오는데 실패했습니다.");
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      if (editingRally) {
        const filtered = editingRally.reservations.filter(
          (reservation: any) =>
            reservation.name.toLowerCase().includes(searchText.toLowerCase()) ||
            reservation.phone.includes(searchText)
        );
        setFilteredReservations(filtered);
      }
    }, [searchText, editingRally]);
  
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    };


    const showModal = (rally: Rally | null = null) => {
      setEditingRally(rally);
      if (rally) {
        form.setFieldsValue({
          name: rally.name,
          date: moment(rally.date),
          time: moment(rally.time, "HH:mm"),
          prizeMoney: rally.prizeMoney,
          maximumPeople: rally.maximumPeople,
          // reservationStart: moment(rally.reservationStart),
          // reservationStartTime: moment(rally.reservationStartTime, "HH:mm"),
          // reservationEnd: moment(rally.reservationEnd),
          // reservationEndTime: moment(rally.reservationEndTime, "HH:mm"),
          coinFee: rally.coinFee,
          isNoLimit: rally.isNoLimit
        });
      } else {
        form.resetFields();
      }
      setIsModalVisible(true);
    };

    const handleOk = async () => {
      try {
        const values = await form.validateFields();
        const rallyData: any = {

          id: editingRally ? editingRally.id : Date.now().toString(),
          name: values.name,
          reservations: editingRally?.reservations || [],
          date: values.date.format("YYYY-MM-DD"),
          time: values.time.format("HH:mm"),
          prizeMoney: values.prizeMoney,
          maximumPeople: values.maximumPeople,
          // reservationStart: values.reservationStart.format("YYYY-MM-DD"),
          // reservationStartTime: values.reservationStartTime.format("HH:mm"),
          // reservationEnd: values.reservationEnd.format("YYYY-MM-DD"),
          // reservationEndTime: values.reservationEndTime.format("HH:mm"),
          isNoLimit: values.isNoLimit || false,
          coinFee: values.coinFee
        };


        console.log(rallyData)
        if (editingRally) {
          await updateRally({ ...rallyData, gsiPartitionKey: "ALL" });
          message.success("대회 정보가 수정되었습니다.");
        } else {
          await createRally(rallyData);
          message.success("새 대회가 추가되었습니다.");
        }

        setIsModalVisible(false);
        form.resetFields();
        fetchRallies();
      } catch (error) {
        console.error("Error saving rally:", error);
        message.error("대회 정보 저장에 실패했습니다.");
      }
    };

    const handleDelete = async (id: string) => {
      try {
        await deleteRally(id);
        message.success("대회가 삭제되었습니다.");
        fetchRallies();
      } catch (error) {
        console.error("Error deleting rally:", error);
        message.error("대회 삭제에 실패했습니다.");
      }
    };

    const handleReservationDelete = async (reservationId: string) => {
      if (!editingRally) return;

      try {
        const updatedReservations = editingRally.reservations?.filter(
          (reservation: any) => reservation.id !== reservationId
        );


        const targetReservation = editingRally.reservations?.filter(
          (reservation: any) => reservation.id === reservationId
        )[0]

        console.log(targetReservation.userId);

        const user = await getUserById(targetReservation.userId);

        const updatedUser = {
          ...user,
          coin: parseInt(user.coin) + parseInt(editingRally.coinFee),
        };

        console.log(updatedUser)
        
        const res = await updateUser(updatedUser, userInfo ,true,  "대회취소");


        const updatedRally = {
          ...editingRally,
          reservations: updatedReservations,
          gsiPartitionKey: "ALL",
        };

        await updateRally(updatedRally);

        await createReservationDeleteLog(user.id, user.branch, user.username, user.nickname, editingRally.name, userInfo.username);

        message.success("예약이 삭제되었습니다.");
        setEditingRally(updatedRally);
        fetchRallies(); // 전체 rally 목록을 새로고침합니다.
      } catch (error) {
        console.error("Error deleting reservation:", error);
        message.error("예약 삭제에 실패했습니다.");
      }
    };


    const columns = [
      {
        title: "대회 이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "대회 일시",
        dataIndex: "date",
        key: "date",
        render: (_: any, record: Rally) => `${record.date} ${record.time}`,
      },
      {
        title: "상금",
        dataIndex: "prizeMoney",
        key: "prizeMoney",
        render: (v: string) => `${parseInt(v).toLocaleString()} GTD`,
      },
      {
        title: "참가 인원",
        dataIndex: "reservations",
        key: "reservations",
        render: (v: any) => `${v ? v.length : 0}명`,
      },
      // {
      //   title: "예약 기간",
      //   key: "reservationPeriod",
      //   render: (_: any, record: Rally) =>
      //     `${record.reservationStart} ${record.reservationStartTime} ~ ${record.reservationEnd} ${record.reservationEndTime}`,
      // },
      {
        title: "최대 인원",
        dataIndex: "maximumPeople",
        key: "maximumPeople",
        render: (v: number, record: Rally) => 
          record.isNoLimit ? `${v}명+` : `${v}명`,
      },
      {
        title: "작업",
        key: "action",
        render: (_: any, record: Rally) => (
          <Space.Compact size="middle">
            <Button
              icon={<EditOutlined />}
              onClick={() => showModal(record)}
              size="small"
              disabled={!userInfo.isRootAdmin}
            >
              수정
            </Button>
            <Button
              icon={<DatabaseOutlined />}
              onClick={() => {
                setIsReservationModalOpen(true);
                setEditingRally(record);
              }}
              size="small"
            >
              목록 확인
            </Button>
            <Popconfirm
              title="이 대회를 삭제하시겠습니까?"
              onConfirm={() => handleDelete(record.id)}
              okText="예"
              cancelText="아니오"
            >
              <Button
                size="small"
                icon={<DeleteOutlined />}
                danger
                disabled={!userInfo.isRootAdmin}
              >
                삭제
              </Button>
            </Popconfirm>
          </Space.Compact>
        ),
      },
    ];

    const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      return date.toLocaleDateString("ko-KR", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    };

    const listColumns = [
      {
        title: "번호",
        dataIndex: "index",
        key: "index",
        render: (v: any, r: any, index: number) => {
          return `No.${index+1}`;
        }
      },
      {
        title: "이름",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "휴대폰번호",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "주민등록번호",
        dataIndex: "idNumber",
        key: "idNumber",
      },
      {
        title: "지점",
        dataIndex: "locationId",
        key: "locationId",
      },
      {
        title: "신청 일시",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text: string) => formatDate(text),
      },
      {
        title: "작업",
        key: "action",
        render: (_: any, record: any) => (
          <Popconfirm
            title="이 예약을 삭제하시겠습니까?"
            onConfirm={() => handleReservationDelete(record.id)}
            okText="예"
            cancelText="아니오"
          >
            <Button icon={<DeleteOutlined />} danger size="small" disabled={!userInfo.isRootAdmin}>
              삭제
            </Button>
          </Popconfirm>
        )
      },
    ];

    return (
      <div>
        <Button onClick={() => showModal()} style={{ marginBottom: "16px" }} disabled={!userInfo.isRootAdmin}>
          새 대회 추가
        </Button>
        <Table
          columns={columns}
          dataSource={rallies}
          rowKey="id"
          loading={loading}
          size={isMobile ? "small" : "middle"}
          scroll={{ x: true}}
          pagination={false}
          
        />
        <Modal
          title={editingRally ? "대회 정보 수정" : "새 대회 추가"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={() => setIsModalVisible(false)}
          width={600}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="대회 이름"
              rules={[{ required: true, message: "대회 이름을 입력해주세요" }]}
            >
              <Input />
            </Form.Item>
            <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
              <Form.Item
                name="date"
                label="대회 일자"
                rules={[{ required: true, message: "대회 일자를 선택해주세요" }]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="time"
                label="대회 시간"
                rules={[{ required: true, message: "대회 시간을 선택해주세요" }]}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </Space>
            {/* <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
              <Form.Item
                name="reservationStart"
                label="예약 시작 일자"
                rules={[
                  { required: true, message: "예약 시작 일자를 선택해주세요" },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="reservationStartTime"
                label="예약 시작 시간"
                rules={[
                  { required: true, message: "예약 시작 시간을 선택해주세요" },
                ]}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </Space>
            <Space style={{ display: "flex", marginBottom: 8 }} align="baseline">
              <Form.Item
                name="reservationEnd"
                label="예약 종료 일자"
                rules={[
                  { required: true, message: "예약 종료 일자를 선택해주세요" },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                name="reservationEndTime"
                label="예약 종료 시간"
                rules={[
                  { required: true, message: "예약 종료 시간을 선택해주세요" },
                ]}
              >
                <TimePicker format="HH:mm" />
              </Form.Item>
            </Space> */}
            <Form.Item
              name="prizeMoney"
              label="상금"
              rules={[{ required: true, message: "상금을 입력해주세요" }]}
            >
              <Input suffix="GTD" />
            </Form.Item>
            
            <Form.Item
              name="coinFee"
              label="등록DP (통합포인트)"
              rules={[{ required: true, message: "등록DP를 입력해주세요" }]}
            >
              <Input suffix="DP" />
            </Form.Item>
            <Form.Item
              name="maximumPeople"
              label="최대 인원"
              rules={[{ required: true, message: "최대 인원을 입력해주세요" }]}
            >
              <Input suffix="명" />
            </Form.Item>
            <Form.Item
              name="isNoLimit"
              valuePropName="checked"
            >
              <Checkbox>최대 인원 특정 해제(체크하면 최대 인원에 제한이 없어집니다. 최대인원을 50으로 설정하면 최대인원은 50+ 로 표기됩니다)</Checkbox>
            </Form.Item>

          </Form>
        </Modal>
        <Modal
          open={isReservationModalOpen}
          onCancel={() => setIsReservationModalOpen(false)}
          footer={null}
          centered
          width={800}
        >
                  <Input
          placeholder="이름 또는 전화번호로 검색"
          value={searchText}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          style={{ marginBottom: 16 }}
        />
          <Table
          dataSource={filteredReservations}
          columns={listColumns}
            scroll={{x: 900}}
            pagination={false}
            rowKey="id"
          />
        </Modal>
      </div>
    );
  };

  export default RallyAdmin;
