import React, { useEffect, useState } from "react";
import { Spin, message, Typography, Select, Button, Space } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import {
  getAllLogs,
  getFieldFilteredLogs,
  getBranchFilteredLogs,
} from "../../utils/log";
import LogList from "../LogList";
import { useRecoilValue } from "recoil";
import { userInfoState } from "../../atom/userInfo";

const { Text } = Typography;
const { Option } = Select;

const filterOptions = [
  { label: '전체', value: '전체' },
  { label: '지점포인트', value: 'point' },
  { label: '랭킹점수', value: 'score' },
  { label: '통합포인트', value: 'coin' },
  { label: '회원삭제', value: 'delete' },
  { label: '대회', value: 'reservation' },
  { label: '지점변경', value: 'branch' },
];

export default function AdminLogs() {
  const [logs, setLogs] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(null);
  const [filterKey, setFilterKey] = useState<string>("전체");
  const [hasMore, setHasMore] = useState(true);
  const userInfo = useRecoilValue(userInfoState);

  const fetchLogs = async (filter: string, isLoadMore: boolean = false) => {
    setLoading(true);
    try {
      let result: any;
      if (userInfo.isRootAdmin) {
        if (filter === "전체") {
          result = await getAllLogs(20, isLoadMore ? lastEvaluatedKey : null);
        } else {
          result = await getFieldFilteredLogs(filter, 20, isLoadMore ? lastEvaluatedKey : null);
        }
      } else {
        result = await getBranchFilteredLogs(userInfo.branch, 20, isLoadMore ? lastEvaluatedKey : null);
      }

      setLogs(prevLogs => isLoadMore ? [...prevLogs, ...result.items] : result.items);
      setLastEvaluatedKey(result.lastEvaluatedKey);
      setHasMore(!!result.lastEvaluatedKey);
    } catch (error) {
      message.error("로그 불러오기에 실패했습니다");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs(filterKey);
  }, [filterKey]);

  const handleFilterChange = (value: string) => {
    setFilterKey(value);
    setLastEvaluatedKey(null);
    setHasMore(true);
  };

  const handleLoadMore = () => {
    fetchLogs(filterKey, true);
  };

  const handleRefresh = () => {
    setLastEvaluatedKey(null);
    setHasMore(true);
    fetchLogs(filterKey);
  };

  return (
    <div>
      <Space.Compact style={{ marginBottom: 16, width: '100%'}}>
        {userInfo.isRootAdmin && (
          <Select
            style={{ width: "100%", minWidth: 150 }}
            value={filterKey}
            onChange={handleFilterChange}
          >
            {filterOptions.map(option => (
              <Option key={option.value} value={option.value}>{option.label}</Option>
            ))}
          </Select>
        )}
        <Button 
          icon={<ReloadOutlined />} 
          onClick={handleRefresh}
          loading={loading}
        >
          새로고침
        </Button>
      </Space.Compact>
      <LogList logs={logs} />
      {hasMore && (
        <div style={{ textAlign: 'center', marginTop: 16 }}>
          <Button onClick={handleLoadMore} loading={loading}>
            더보기
          </Button>
        </div>
      )}
    </div>
  );
}