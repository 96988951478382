import React from "react";
import { List, Space, Tag, Typography, Card } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  UserOutlined,
  BranchesOutlined,
  TrophyOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { formatTimestamp, getChangeColor, getFieldColor } from "../utils/log";
import { branchOptions } from "../constants/branch";

const { Text } = Typography;

interface Log {
  field: string;
  oldValue?: number;
  newValue?: number;
  nickname?: string;
  userId?: string;
  prevBranch?: number;
  targetBranch?: number;
  username?: string;
  branch?: number;
  fromUsername?: string;
  fromBranch?: string;
  reason?: string;
  changeAmount: number;
  timestamp: string;
  rallyName?: string;
  filterKey: string;
}

interface LogListProps {
  logs: Log[];
}

const getFieldLabel = (field: string): string => {
  if (field.startsWith("point_") || field.startsWith("score_")) {
    const type = field.startsWith("point_") ? "지점포인트" : "랭킹점수";
    const branch = branchOptions.find(
      (b) => b.label === field.split("_")[1]
    )?.label;
    return `${type} (${branch})`;
  }
  if (field === "branch") return "지점변경";
  if (field === "reservationDelete") return "대회취소";
  if (field === "reservationCreate") return "대회등록";
  if (field === "userDelete") return "회원삭제";
  return field.toUpperCase().replace("COIN", "통합포인트");
};

const ValueChange: React.FC<{
  field: string;
  oldValue?: number;
  newValue?: number;
}> = ({ field, oldValue, newValue }) => (
  <Space wrap>
    {/* <DollarOutlined style={{ color: '#1890ff' }} />  */}
    <Text style={{ color: "#777" }}>{getFieldLabel(field)}</Text>
    <Text style={{ color: "#bfbfbf", fontWeight: 700 }}>
      {oldValue || 0} → {newValue || 0}
    </Text>
  </Space>
);

const UserInfo: React.FC<{
  fromUsername?: string;
  username?: string;
  userId?: string;
  branch?: string | number;
  nickname?: string;
}> = ({ fromUsername, username, userId, branch, nickname }) => (
  <Space wrap>
    <UserOutlined style={{ color: "#777" }} />
    <Text style={{ color: "#bfbfbf", fontWeight: 700 }}>
      {fromUsername} ({username}/{nickname})
    </Text>
    {branch && (
      <>
        <BranchesOutlined style={{ color: "#777" }} />
        <Text style={{ color: "#bfbfbf", fontWeight: 700 }}>{branch}</Text>
      </>
    )}
  </Space>
);

const BranchChange: React.FC<{
  prevBranch?: number;
  targetBranch?: number;
}> = ({ prevBranch, targetBranch }) => (
  <Space wrap>
    <BranchesOutlined style={{ color: "#777" }} />
    <Text style={{ color: "#bfbfbf", fontWeight: 700 }}>
      {prevBranch || 0} → {targetBranch || 0}
    </Text>
  </Space>
);

const ReservationDelete: React.FC<{ rallyName?: string }> = ({ rallyName }) => (
  <Space wrap>
    <TrophyOutlined style={{ color: "#777" }} />
    <Text style={{ color: "#bfbfbf", fontWeight: 700 }}>{rallyName}</Text>
  </Space>
);

const ChangeAmount: React.FC<{ amount: number }> = ({ amount }) => (
  <Text strong style={{ fontSize: "1.1em", color: getChangeColor(amount) }}>
    {amount > 0 ? (
      <ArrowUpOutlined />
    ) : amount < 0 ? (
      <ArrowDownOutlined />
    ) : null}
    {amount > 0 ? "+" : ""}
    {amount}
  </Text>
);

const LogItem: React.FC<{ item: Log }> = ({ item }) => (
  <List.Item style={{ borderBlockEnd: "1px solid #333" }}>
    <Card
      style={{
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "transparent",
        border: "none",
        padding: 0,
      }}
      bodyStyle={{ padding: "8px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="small">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <Space direction="vertical" style={{ marginBottom: 8 }}>
            <div style={{ display: "flex" }}>
              <Tag
                color={getFieldColor(item.field)}
                style={{
                  borderRadius: "4px",
                  padding: "2px 8px",
                  marginBottom: "8px",
                  display: "flex",
                  gap: 6, 
                  alignItems: "center"
                }}
              >
                {getFieldLabel(item.field)}
                {item.filterKey === "reservation" && (
                <div
                  style={{
                    borderRadius: 10,
                    width: 10,
                    height: 10,
                    background:
                      item.field === "reservationCreate"
                        ? "#00a536"
                        : "#ff4d4f",
                  }}
                ></div>
              )}
              </Tag>
              
            </div>

            <Space direction="vertical">
              <UserInfo
                fromUsername={item.fromUsername}
                username={item.username}
                userId={item.userId}
                branch={item.branch}
                nickname={item.nickname}
              />
              {item.field !== "branch" &&
              item.field !== "reservationDelete" &&
              item.field !== "reservationCreate" &&
              item.field !== "userDelete" ? (
                <ValueChange
                  field={item.field}
                  oldValue={item.oldValue}
                  newValue={item.newValue}
                />
              ) : item.field === "branch" ? (
                <BranchChange
                  prevBranch={item.prevBranch}
                  targetBranch={item.targetBranch}
                />
              ) : item.field === "userDelete" ? (
                <></>
              ) : (
                <ReservationDelete rallyName={item.rallyName} />
              )}
            </Space>
          </Space>
          <ChangeAmount amount={item.changeAmount} />
        </div>
        {item.reason && (
          <Text
            type="secondary"
            style={{ fontSize: "0.9em", color: "#8c8c8c" }}
          >
            Reason: {item.reason}
          </Text>
        )}
        <Text
          type="secondary"
          style={{ fontSize: "0.8em", color: "#8c8c8c", marginTop: "8px" }}
        >
          {formatTimestamp(item.timestamp)}
        </Text>
      </Space>
    </Card>
  </List.Item>
);

export const LogList: React.FC<LogListProps> = ({ logs }) => (
  <List
    dataSource={logs}
    renderItem={(item) => <LogItem item={item} />}

    // style={{ padding: '16px', backgroundColor: '#141414' }}
  />
);

export default LogList;
